<template>
  <nav class="menu" :class="{ 'menu--hide': $store.state.sidebar && !show && !mobileShow, 'menu--show': show }">
    <div class="menu__item">
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'members' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/user-icon.svg" alt="">
        <div class="menu__text">Участники</div>
      </router-link>
      <router-link
        v-if="userAdmin || this.$store.state.user_role === 'staff'"
        :to="{ name: 'directorate-list' }"
        class="menu__link"
        active-class="menu__link--active"
      >
        <img class="menu__icon" src="@/assets/img/sidebar/org.svg" alt="">
        <div class="menu__text">Дирекции</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'numbers' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/guide.svg" alt="">
        <div class="menu__text">Номера</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'contracts' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/contracts-icon.svg" alt="">
        <div class="menu__text">Договоры</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'call-letter' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/call-letter-icon.svg" alt="">
        <div class="menu__text">Письмо-вызов</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'thank-note' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/call-letter-icon.svg" alt="">
        <div class="menu__text">Благодарственные письма</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff'" :to="{ name: 'transfer' }" class="menu__link"
                   active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/transfer-icon.svg" alt="">
        <div class="menu__text">Трансфер</div>
      </router-link>
      <router-link v-if="this.$store.state.user_role !== 'staff' && app_name === 'poo'"
                   :to="{ name: 'realizationreport' }" class="menu__link" active-class="menu__link--active">
        <img class="menu__icon" src="@/assets/img/sidebar/doc-icon.svg" alt="">
        <div class="menu__text">Отчет о реализации</div>
      </router-link>
    </div>
    <template v-if="userAdmin">
      <div class="menu__line"></div>
      <div class="menu__item" v-for="item in timeMenu" :key="item.id">
        <router-link
          v-if="!item.children"
          :to="{ name: item.link }"
          class="menu__link"
          active-class="menu__link--active"
          @click.native="onCloseAllDD"
        >
          <img
            :src="require('@/assets/img/sidebar/' + item.icon)"
            :alt="item.link"
            class="menu__icon"
          />
          <div class="menu__text">{{ item.name }}</div>
        </router-link>
        <template v-else>
          <div class="menu__link menu__link--dd" :class="{ 'is-show': item.is_open || item.always_open }"
               @click="item.is_open = !item.is_open">
            <img
              :src="require('@/assets/img/sidebar/' + item.icon)"
              :alt="item.link"
              class="menu__icon"
            />
            <div class="menu__text">{{ item.name }}</div>
          </div>
          <ul v-if="item.children" class="dd-menu" :class="{ 'is-show': item.is_open || item.always_open }">
            <li v-for="chi in item.children" :key="chi.id">
              <router-link
                :to="{ name: chi.link }"
                class="dd-menu__item"
                active-class="dd-menu__item--active"
                @click.native="onCloseAllDD(item)"
              >
                {{ chi.name }}
              </router-link>
            </li>
          </ul>
        </template>
      </div>
    </template>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  props: ['show', 'mobileShow', 'userAdmin'],
  data() {
    return {
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
      timeMenu: [
        {
          id: 1,
          name: 'Экспорт',
          link: 'export',
          icon: 'export.svg',
        },
        {
          id: 2,
          name: 'Справочники',
          link: 'documents',
          icon: 'doc-icon.svg',
          is_open: false,
          always_open: false,
          children: [
            {
              id: 2.1,
              name: 'ФМС',
              link: 'fms',
              icon: 'guide.svg',
            },
            {
              id: 2.2,
              name: 'Организации',
              link: 'org',
              icon: 'org.svg',
            },
          ]
        },
        {
          id: 3,
          name: 'Пользователи',
          link: 'admin-users-list',
          icon: 'users-icon.svg',
        },
      ],
      menu: [
        {
          id: 1,
          name: 'Профиль',
          link: 'profile',
          icon: 'user-icon.svg',
        },
        {
          id: 2,
          name: 'Документы',
          link: 'documents',
          icon: 'doc-icon.svg',
          is_open: false,
          always_open: false,
          children: [
            {
              id: 2.1,
              name: 'Образование',
              link: 'education',
              icon: 'user-icon.svg',
            },
            {
              id: 2.2,
              name: 'Индивидуальные достижения',
              link: 'individual',
              icon: 'user-icon.svg',
            },
            {
              id: 2.3,
              name: 'Льготы',
              link: 'benefits',
              icon: 'user-icon.svg',
            },
            {
              id: 2.4,
              name: 'Целевое обучение',
              link: 'targeted',
              icon: 'user-icon.svg',
            },
          ]
        },
        {
          id: 3,
          name: 'Заявление',
          link: 'statement',
          icon: 'docs-icon.svg',
        },
        {
          id: 4,
          name: 'Вступительные испытания',
          link: 'viv',
          icon: 'graduation-icon.svg',
          needViv: true,
        },
        {
          id: 5,
          name: 'Результаты ЕГЭ / ВИВ',
          link: 'exam-points',
          icon: 'user-icon.svg',
        },
      ],
      isDDOpen: false,
    }
  },
  methods: {
    onCloseAllDD(subItem) {
      this.menu.forEach(item => {
        if (item.children && !item.always_open && item !== subItem) {
          item.is_open = false
        }
      })
    },
  }
}
</script>

<style lang="sass">
.menu
  padding-top: 10px

.menu--hide
  .menu__text
    display: none

  .menu__link--dd:after
    content: none

  //&.is-show
  //  display: none

  .dd-menu.is-show
    max-height: 0

.menu--show
  .menu__text
    display: block

  .menu__link--dd:after
    content: ''

  .dd-menu
    max-height: 300px

    &:not(.is-show)
      max-height: 0

.menu__item
  position: relative

.menu__link
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &:hover
    color: $color-theme

.menu__link--dd
  &:after
    content: ''
    position: absolute
    top: -5px
    right: 0
    width: 52px
    height: 52px
    background-image: url("~@/assets/img/sidebar/arrow-bottom.svg")
    background-repeat: no-repeat
    background-position: center
    background-size: 11px
    pointer-events: none
    transition: transform 0.3s

  &.is-show:after
    transform: rotate(180deg)

.menu__toggler
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &:hover
    color: $color-theme

  &.is-show
    .menu__arrow
      transform: rotate(180deg)

.menu__link--active
  border-left-color: $color-theme
  background-color: #f1f8fe

  .menu__text
    color: $color-theme
    font-weight: 600

.menu__icon
  margin-right: 18px

.menu__text
  font-size: 16px
  white-space: break-spaces

.menu__submenu
  max-height: 0
  padding-left: 95px
  overflow: hidden
  transition: max-height 0.3s

  &.is-show
    max-height: 165px

  li
    position: relative
    margin-bottom: 10px

    &::before
      content: ''
      position: absolute
      top: 8px
      left: -16px
      width: 7px
      height: 7px
      border-radius: 50%
      background-color: $color-theme

.menu__subitem
  font-size: 16px
  color: $color-text-base
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &.active
    color: $color-theme

  &:hover
    color: $color-theme

.menu__subitem--active
  color: $color-theme

.menu__arrow
  position: absolute
  top: 0
  right: 0
  width: 52px
  height: 52px
  background-image: url("~@/assets/img/sidebar/arrow-bottom.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 11px
  pointer-events: none
  transition: transform 0.3s

.menu__line
  margin: 10px auto
  width: 80%
  height: 1px
  background-color: #c7cfdd

</style>
