import Vue from 'vue'
import VueRouter from 'vue-router'
import {isLoggedIn} from 'axios-jwt'
import Login from '@/views/auth/Login'
import MembersHashCreate from '@/views/members/HashCreate'
import DirectorateHashCreate from '@/views/directorate/HashCreate'
import Export from '@/views/Export'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: {name: 'members'}
  },
  {
    path: '/members/delegation/:id',
    name: 'members-hash-create',
    component: MembersHashCreate,
    meta: {
      title: 'Анкета участника',
      layout: 'public',
    }
  },
  {
    path: '/members',
    name: 'members',
    redirect: {name: 'members-list'},
    component: () => import('@/views/members/Index'),
    children: [
      {
        path: '',
        name: 'members-list',
        component: () => import('@/views/members/List'),
        meta: {
          title: 'Список участников',
        }
      },
      {
        path: 'create',
        name: 'members-create',
        component: () => import('@/views/members/Create'),
        meta: {
          title: 'Создание участника',
        }
      },
      {
        path: 'stat',
        name: 'members-stat',
        component: () => import('@/views/members/Stat'),
        meta: {
          title: 'Статистика'
        },
      },
      {
        path: ':id',
        name: 'members-detail',
        component: () => import('@/views/members/Detail'),
        meta: {
          title: 'Редактирование участника',
        }
      },
    ]
  },
  {
    path: '/dicts',
    name: 'dicts',
    component: () => import('@/views/dicts/Index'),
    redirect: {name: 'fms'},
    children: [
      {
        path: 'fms',
        name: 'fms',
        component: () => import('@/views/dicts/fms/Index'),
        redirect: {name: 'fms-list'},
        children: [
          {
            path: '',
            name: 'fms-list',
            component: () => import('@/views/dicts/fms/List'),
            meta: {
              title: 'Список ФМС',
              requiresAuth: true,
            }
          },
          {
            path: 'create',
            name: 'fms-create',
            component: () => import('@/views/dicts/fms/Create'),
            meta: {
              title: 'Добавление ФМС',
              requiresAuth: true,
            }
          },
          {
            path: ':id',
            name: 'fms-edit',
            component: () => import('@/views/dicts/fms/Edit'),
            meta: {
              title: 'Редактирование ФМС',
              requiresAuth: true,
            }
          },
        ]
      },
      {
        path: 'organization',
        name: 'org',
        component: () => import('@/views/dicts/org/Index'),
        redirect: {name: 'org-list'},
        children: [
          {
            path: '',
            name: 'org-list',
            component: () => import('@/views/dicts/org/List'),
            meta: {
              title: 'Список организаций',
              requiresAuth: true,
            },
          },
          {
            path: 'create',
            name: 'org-create',
            component: () => import('@/views/dicts/org/Create'),
            meta: {
              title: 'Добавление организации',
              requiresAuth: true,
            }
          },
          {
            path: ':id',
            name: 'org-edit',
            component: () => import('@/views/dicts/org/Edit'),
            meta: {
              title: 'Редактирование организации',
              requiresAuth: true,
            }
          },
        ]
      },
    ]
  },
  {
    path: '/users',
    component: () => import('@/views/users/Index'),
    redirect: {name: 'admin-users-list'},
    children: [
      {
        path: '',
        name: 'admin-users-list',
        component: () => import('@/views/users/List'),
        meta: {
          title: 'Список всех пользователей'
        }
      },
    ]
  },
  {
    path: '/numbers',
    component: () => import('@/views/numbers/Index'),
    children: [
      {
        path: '',
        name: 'numbers',
        component: () => import('@/views/numbers/Layout'),
        redirect: {name: 'numbers-list'},
        children: [
          {
            path: '',
            name: 'numbers-list',
            component: () => import('@/views/numbers/List'),
            meta: {
              title: 'Список номеров',
            }
          },
          {
            path: 'program',
            name: 'numbers-program',
            component: () => import('@/views/numbers/Program'),
            meta: {
              title: 'Программа',
            }
          },
          {
            path: 'list',
            name: 'grades-list',
            component: () => import('@/views/numbers/GradesList'),
            meta: {
              title: 'Оценочные листы',
            }
          },
          {
            path: 'award',
            name: 'award',
            component: () => import('@/views/numbers/Award'),
            meta: {
              title: 'Награждение'
            }
          },
        ]
      },
      {
        path: 'create',
        name: 'numbers-create',
        component: () => import('@/views/numbers/Create'),
        meta: {
          title: 'Создание номера',
        }
      },
      {
        path: 'stat',
        name: 'numbers-stat',
        component: () => import('@/views/numbers/Stat'),
        meta: {
          title: 'Статистика'
        },
      },
      {
        path: ':id',
        name: 'numbers-detail',
        component: () => import('@/views/numbers/Detail'),
        meta: {
          title: 'Редактирование номера',
        }
      },
    ]
  },
  {
    path: '/contracts',
    name: 'contracts',
    redirect: {name: 'contracts-list'},
    component: () => import('@/views/contracts/Index'),
    children: [
      {
        path: '',
        name: 'contracts-list',
        component: () => import('@/views/contracts/List'),
        meta: {
          title: 'Список договоров',
        }
      },
      {
        path: 'stat',
        name: 'contracts-stat',
        component: () => import('@/views/contracts/Stat'),
        meta: {
          title: 'Статистика'
        },
      },
      {
        path: 'legal',
        name: 'contracts-legal-index',
        redirect: {name: 'contracts-legal-create'},
        component: () => import('@/views/contracts/legal/Index'),
        children: [
          {
            path: 'create',
            name: 'contracts-legal-create',
            component: () => import('@/views/contracts/legal/Create'),
            meta: {
              title: 'Создание договора',
            }
          },
          {
            path: ':id',
            name: 'contracts-legal-edit',
            component: () => import('@/views/contracts/legal/Detail'),
            meta: {
              title: 'Редактирование договора',
            }
          },
        ]
      },
      {
        path: 'individual',
        name: 'contracts-individual-index',
        redirect: {name: 'contracts-individual-create'},
        component: () => import('@/views/contracts/individual/Index'),
        children: [
          {
            path: 'create',
            name: 'contracts-individual-create',
            component: () => import('@/views/contracts/individual/Create'),
            meta: {
              title: 'Создание договора',
            }
          },
          {
            path: ':id',
            name: 'contracts-individual-edit',
            component: () => import('@/views/contracts/individual/Detail'),
            meta: {
              title: 'Редактирование договора',
            }
          },
        ]
      },
    ]
  },
  {
    path: '/call-letter',
    name: 'call-letter',
    redirect: {name: 'call-letter-list'},
    component: () => import('@/views/call-letter/Index'),
    children: [
      {
        path: '',
        name: 'call-letter-list',
        component: () => import('@/views/call-letter/List'),
        meta: {
          title: 'Список писем-вызовов',
        }
      },
      {
        path: 'create',
        name: 'call-letter-create',
        component: () => import('@/views/call-letter/Create'),
        meta: {
          title: 'Создание письма-вызова',
        }
      },
      {
        path: ':id',
        name: 'call-letter-detail',
        component: () => import('@/views/call-letter/Detail'),
        meta: {
          title: 'Редактирование письма-вызова',
        }
      },
    ]
  },
  {
    path: '/directorate',
    name: 'directorate',
    redirect: {name: 'directorate-list'},
    component: () => import('@/views/directorate/Index'),
    children: [
      {
        path: '',
        name: 'directorate-list',
        component: () => import('@/views/directorate/management/List'),
        meta: {
          title: 'Список служб',
        }
      },

      {
        path: 'experts',
        name: 'experts-list',
        component: () => import('@/views/directorate/experts/List'),
        meta: {
          title: 'Список экспертов',
        }
      },
    ]
  },
  {
    path: '/directorate/create',
    name: 'directorate-create',
    component: () => import('@/views/directorate/management/Create'),
    meta: {
      title: 'Создание службы',
    }
  },
  {
    path: '/directorate/:id',
    name: 'directorate-detail',
    component: () => import('@/views/directorate/management/Detail'),
    meta: {
      title: 'Редактирование службы',
    }
  },
  {
    path: '/directorate/experts/create',
    name: 'experts-create',
    component: () => import('@/views/directorate/experts/Create'),
    meta: {
      title: 'Создание эксперта',
    }
  },
  {
    path: '/directorate/experts/:id',
    name: 'experts-detail',
    component: () => import('@/views/directorate/experts/Detail'),
    meta: {
      title: 'Редактирование эксперта',
    }
  },
  {
    path: '/directorate/:hash/:securityHash',
    name: 'directorate-hash-create',
    component: DirectorateHashCreate,
    meta: {
      title: 'Анкета службы',
      layout: 'public',
    }
  },
  {
    path: '/transfer',
    name: 'transfer',
    redirect: {name: 'transfer-list'},
    component: () => import('@/views/transfer/Index'),
    children: [
      {
        path: '',
        name: 'transfer-list',
        component: () => import('@/views/transfer/List'),
        meta: {
          title: 'Список трансферов',
        }
      },
      {
        path: 'create',
        name: 'transfer-create',
        component: () => import('@/views/transfer/Create'),
        meta: {
          title: 'Создание трансфера',
        }
      },
      {
        path: ':id',
        name: 'transfer-detail',
        component: () => import('@/views/transfer/Detail'),
        meta: {
          title: 'Редактирование трансфера',
        }
      },
    ]
  },
  // {
  //   path: '/smi/public/:securityHash',
  //   name: 'smi-public-create',
  //   component: SmiPublicCreate,
  //   meta: {
  //     title: 'Анкета СМИ',
  //     layout: 'public',
  //   }
  // },
  // {
  //   path: '/smi',
  //   name: 'smi',
  //   redirect: { name: 'smi-list' },
  //   component: () => import('@/views/smi/Index'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'smi-list',
  //       component: () => import('@/views/smi/List'),
  //       meta: {
  //         title: 'Список СМИ',
  //       }
  //     },
  //     {
  //       path: 'create',
  //       name: 'smi-create',
  //       component: () => import('@/views/smi/Create'),
  //       meta: {
  //         title: 'Создание СМИ',
  //       }
  //     },
  //     {
  //       path: ':id',
  //       name: 'smi-detail',
  //       component: () => import('@/views/smi/Detail'),
  //       meta: {
  //         title: 'Редактирование СМИ',
  //       }
  //     },
  //   ]
  // },
  {
    path: '/export',
    name: 'export',
    component: Export,
    meta: {
      title: 'Экспорт',
    }
  },
  {
    path: '/realizationreport',
    redirect: { name: 'realizationreport' },
    component: () => import('@/views/realizationReport/Index'),
    children: [
      {
        path: '',
        name: 'realizationreport',
        component: () => import('@/views/realizationReport/RealizationReport'),
        meta: {
          title: 'Отчет о реализации',
        }
      },
      {
        path: 'stat',
        name: 'realizationreport-stat',
        component: () => import('@/views/realizationReport/Stat'),
        meta: {
          title: 'Отчет о реализации',
        }
      },
    ]
  },
  {
    path: '/thank-note',
    name: 'thank-note',
    redirect: { name: 'thank-note-list' },
    component: () => import('@/views/thank-note/Index'),
    children: [
      {
        path: '',
        name: 'thank-note-list',
        component: () => import('@/views/thank-note/List'),
        meta: {
          title: 'Благодарственные письма',
        }
      },
      {
        path: 'create',
        name: 'thank-note-create',
        component: () => import('@/views/thank-note/Create'),
        meta: {
          title: 'Добавление индивидуального благодарственного письма',
        }
      },
      {
        path: ':id',
        name: 'thank-note-detail',
        component: () => import('@/views/thank-note/Detail'),
        meta: {
          title: 'Редактирование благодарственное письмо',
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'auth',
      title: 'Авторизация',
    }
  },
  {
    path: '*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  const loggedIn = isLoggedIn()

  if (loggedIn) {
    if (['login', 'password-forgot', '404'].includes(to.name)) next({name: 'members'})
    else next()
  } else {
    if (['login', 'password-forgot', '404', 'members-hash-create', 'directorate-hash-create', 'smi-public-create'].includes(to.name)) next()
    else next({name: 'login'})
  }
  next()
  document.title = `${to.meta.title} | СУМ РСВ ${process.env.VUE_APP_FESTIVAL_NAME === 'oovo' ? 'ООВО' : 'ПОО'}`
})

export default router
