import Vue from 'vue'
import AppPhone from '@/components/AppPhone'
import AppInput from '@/components/AppInput'
import AppFormGroup from '@/components/AppFormGroup'
import AppButton from '@/components/AppButton'
import AppCells from '@/components/AppCells'
import AppTableHead from '@/components/AppTableHead'
import AppFilters from '@/components/AppFilters'
import AppSidebarRight from '@/components/AppSidebarRight'
import AppTextarea from '@/components/AppTextarea'
import AppTabs from '@/components/AppTabs'
import AppRadio from '@/components/AppRadio'
import AppStatus from '@/components/AppStatus'
import AppCheckbox from '@/components/AppCheckbox'
import AppGrid from '@/components/AppGrid'
import AppCropper from '@/components/AppCropper'
import AppUploader from '@/components/AppUploader'
import AppCropperWebCam from '@/components/AppCropperWebCam'
import AppTooltip from '@/components/AppTooltip'

Vue.component('AppPhone', AppPhone)
Vue.component('AppInput', AppInput)
Vue.component('AppFormGroup', AppFormGroup)
Vue.component('AppButton', AppButton)
Vue.component('AppCells', AppCells)
Vue.component('AppTableHead', AppTableHead)
Vue.component('AppFilters', AppFilters)
Vue.component('AppSidebarRight', AppSidebarRight)
Vue.component('AppTextarea', AppTextarea)
Vue.component('AppTabs', AppTabs)
Vue.component('AppRadio', AppRadio)
Vue.component('AppStatus', AppStatus)
Vue.component('AppCheckbox', AppCheckbox)
Vue.component('AppGrid', AppGrid)
Vue.component('AppCropper', AppCropper)
Vue.component('AppCropperWebCam', AppCropperWebCam)
Vue.component('AppUploader', AppUploader)
Vue.component('AppTooltip', AppTooltip)
