<template>
  <label class="radio" :class="{ 'radio--readonly': readonly }">
    <span class="radio__text">{{ label }}</span>
    <input
        type="radio"
        :value="value"
        :checked="value === checked"
        :name="name"
        @change="$emit('change', $event.target.value)"
    />
    <span class="radio__radiomark"></span>
  </label>
</template>

<script>
export default {
  name: 'AppRadio',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    value: [Boolean, String, Number],
    checked: [Boolean, String, Number],
    label: [String, Number],
    id: String,
    name: String,
    readonly: Boolean,
  },
}
</script>

<style lang="sass">
.radio
  display: flex
  align-items: center
  position: relative
  padding-left: 35px
  cursor: pointer

  input
    +visually-hidden

    &:checked ~ .radio__radiomark
      border-color: $color-theme

      &:after
        content: ""

.radio--readonly
  pointer-events: none
  cursor: default

  input
    & ~ .radio__radiomark
      border-color: $color-readonly

      &:after
        background-color: $color-readonly

    &:checked ~ .radio__radiomark
      border-color: $color-readonly

.radio__radiomark
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 3px
  left: 0
  width: 15px
  height: 15px
  background-color: #fff
  border: 1px solid rgba(56, 123, 191, 0.3)
  box-sizing: border-box
  border-radius: 50%

  +max-w($mobile_md)
    top: 1px
    width: 18px
    height: 18px

  &:after
    content: none
    width: 7px
    height: 7px
    background-color: $color-theme
    border-radius: 50%

    +max-w($mobile_md)
      width: 10px
      height: 10px

.radio__text
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: $color-text-base
</style>
