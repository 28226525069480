<template>
  <div class="status" :class="[ `status--${status}` ]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppStatus',
  props: {
    status: {
      type: String,
      default: 'new'
    },
  }
}
</script>

<style scoped lang="sass">
.status
  display: block
  padding: 7px 19px
  border: 1px solid
  border-radius: 10px
  text-align: center

.status--cancel
  border-color: $color-error
  color: $color-error
  background-color: rgba($color-error, 0.2)

.status--close
  border-color: $color-error
  color: $color-error
  background-color: rgba($color-error, 0.2)

.status--new
  border-color: $color-theme
  color: $color-theme
  background-color: rgba($color-theme , 0.2)

.status--open
  border-color: $color-error
  color: $color-error
  background-color: rgba($color-error, 0.2)
</style>
