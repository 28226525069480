<template>
  <label class="checkbox" :class="{ 'checkbox--readonly': readonly }">
    <span class="checkbox__text">{{ label }}</span>
    <input
        type="checkbox"
        :checked="value"
        @change="$emit('change', $event.target.checked)"
    />
    <span class="checkbox__checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: [String, Boolean],
    label: String,
    readonly: Boolean
  },
}
</script>

<style lang="sass">
.checkbox
  display: flex
  align-items: center
  position: relative
  padding-left: 35px
  cursor: pointer
  user-select: none

  input
    +visually-hidden

    &:checked ~ .checkbox__checkmark
      border-color: $color-theme

      &:after
        content: ""

.checkbox--many
  margin-right: 30px

  &:last-child
    margin-right: 0

.checkbox--table
  display: inline
  padding-left: 20px

.checkbox--readonly
  pointer-events: none

  .checkbox__checkmark
    border-color: #ff9416

  input:checked ~ .checkbox__checkmark
    border-color: #ff9416

    &:after
      background-color: #ff9416

.checkbox__checkmark
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 0
  left: 0
  width: 20px
  height: 20px
  background-color: #fff
  border: 1px solid rgba(56, 123, 191, 0.3)
  box-sizing: border-box
  border-radius: 3px

  &:after
    content: none
    width: 12px
    height: 12px
    background-color: $color-theme

.checkbox__text
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: $color-text-base

  +max-w($mobile_sm)
    font-size: 15px

  a
    color: $color-theme
    text-decoration: none

    &:hover
      text-decoration: underline
</style>
