<template>
  <div class="tabs-list">
    <ul class="tabs-list__body">
      <li v-for="tab in tabs" :key="tab.id" class="tabs-list__item">
        <router-link
          :to="{ name: tab.name }"
          exact-active-class="tabs-list__link--active"
          class="tabs-list__link"
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppTabs',
  props: {
    tabs: Array
  }
}
</script>

<style lang="sass" scoped>
.tabs-list
  margin-bottom: 20px
  padding-bottom: 1px
  border-bottom: 1px solid #e3e3e3
  overflow-x: auto
  overflow-y: hidden

.tabs-list__body
  display: flex

.tabs-list__item
  flex-shrink: 0
  margin-right: 30px

  &:last-child
    margin-right: 0

.tabs-list__link
  position: relative
  display: block
  padding-bottom: 10px
  color: $color-theme
  font-size: 16px
  font-weight: 600
  text-decoration: none
  transition: color 0.3s, opacity 0.3s

  &:after
    content: ''
    position: absolute
    left: 0
    bottom: -1px
    width: 100%
    height: 2px
    border-radius: 1px
    background-color: $color-theme
    opacity: 0
    transition: opacity 0.3s

  &:not(.tabs-list__link--active)
    @media (hover: hover)
      &:hover,
      &:focus-visible
        opacity: 0.7

.tabs-list__link--active
  color: $color-text-base

  &:after
    opacity: 1
</style>
