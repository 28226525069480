<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <notifications classes="my-notify" width="416" :duration="5000" position="left bottom" />
  </div>
</template>

<script>

export default {
  computed: {
    layout () {
      return this.$route.meta.layout || 'default'
    }
  },
}
</script>

<style lang="sass">
#app
  display: flex
  flex-grow: 1
  width: 100%
  height: 100%
</style>
