<template>
  <textarea
    @input="$emit('input', $event.target.value)"
    :value="value"
    class="textarea"
    :class="{'textarea--error': error}"
  >
  </textarea>
</template>

<script>
export default {
  name: 'AppTextarea',
  props: {
    error: Boolean,
    value: String,
  }
}
</script>

<style scoped lang="sass">
.textarea
  display: block
  width: 100%
  height: 150px
  padding: 12px 25px 11px 20px
  background-color: #fff
  border: 1px solid $color-border
  box-sizing: border-box
  border-radius: 7px
  font-family: $font-family-base
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: $color-text-base
  transition: border-color 0.3s
  resize: none
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

  &::placeholder
    font-family: $font-family-base
    font-size: 16px
    font-weight: 400
    line-height: 20px
    color: $color-text-placeholder

  &:focus
    border-color: $color-theme
    outline: none

  &[readonly]
    pointer-events: none
    border-color: $color-readonly

  +max-w($mobile_md)
    padding: 12px 20px 11px 10px

.textarea--error
  border-color: $color-error
</style>
