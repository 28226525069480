<template>
  <input
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="type"
      class="input"
      :class="{'input--error': error}"
  />
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      default: 'text',
      validator: value => {
        return value === 'text' || value === 'email' || value === 'password' || value === 'url' || value === 'number'
      }
    },
    error: Boolean,
  },
}
</script>

<style lang="sass">
.input
  width: 100%
  padding: 12px 15px 11px 20px
  border: 1px solid #c7cfdd
  border-radius: 7px
  color: $color-text-base
  background-color: #fff
  font-size: 16px
  font-weight: 400
  line-height: 20px
  transition: border-color .3s

  +max-w($mobile_sm)
    padding: 12px 12px 11px

  &::placeholder
    color: $color-text-placeholder

    +max-w($mobile_sm)
      font-size: 14px

  &:focus
    border-color: $color-theme

  &[readonly]
    pointer-events: none
    border-color: $color-readonly

.input--error
  border-color: $color-error

.input--maxw
  max-width: 450px
</style>
