import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import VueTables from 'vue-tables-2-premium'
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate'
const VueInputMask = require('vue-inputmask').default
import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip'
import VueClip from 'vue-clip'

import DefaultLayout from './layouts/Default'
import AuthLayout from './layouts/Auth'
import PublicLayout from './layouts/Public'

Vue.component('default', DefaultLayout)
Vue.component('auth', AuthLayout)
Vue.component('public', PublicLayout)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)

Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueTables.ClientTable)
Vue.use(VueInputMask)
Vue.use(VModal)
Vue.use(VTooltip, {
  defaultHtml: false,
})
Vue.use(VueClip)
