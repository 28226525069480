import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebar: false,
    sidebarOpen: false,
    delegation: null,
    delegation_hash: '',
    user_role: '',
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setDelegation(state, payload) {
      state.delegation = payload
    },
    setDelegationHash(state, payload) {
      state.delegation_hash = payload
    },
    setUserRole(state, payload) {
      state.user_role = payload
    },
  },
  actions: {},
  modules: {}
})
